<template>
  <div class="talentAb">
    <div class="talentAb_title">Our Talent Ambassador</div>
    <div class="talentAb_tip">
      Step into the world of limitless possibilities as we unveil the
      captivating story of our esteemed Talent Ambassador.
    </div>
    <button class="viewAllbtn" @click="openNew('/hr/talent')">
      View all 
    </button>
    <div class="person" v-for="(item, i) in list" :key="i">
      <div class="person_img">
        <img
        style="object-fit: cover"
          v-if="item.avatarList.length > 0"
          class="imgperson"
          :src="item.avatarList[0].link"
          alt=""
        />
      </div>
      <div class="person_information">
        <div class="person_data">
          <span class="note">“ </span>
          <span v-html="item.aboutMe.replace(/\n/g, '<br>')"></span>
          <span class="note">”</span>
        </div>
        <div class="person_data_more">
          <collapse :text="item.description"></collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minxin } from "@/mixin.js";
import collapse from "./collapse.vue";
export default {
  mixins: [minxin],
  data() {
    return {
      list: [],
    };
  },
  components: {
    collapse,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await this.$http.get(
        `${this.$url.hr.talentList}?current=1&&size=1`
      );
      if (response.code == 200) {
        this.list = response.data.records;
      } else {
        this.list = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.talentAb {
  width: 1440px;
  margin: auto;
  height: auto;
  padding: 60px 170px 50px 170px;
  //   background-color: antiquewhite;
  box-sizing: border-box;
  font-family: Arial;
  position: relative;
  .talentAb_title {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 43.2px */
    letter-spacing: -0.54px;
    background: var(
      --primary-linear,
      linear-gradient(90deg, #3574af 0%, #47adff 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .talentAb_tip {
    color: var(--mcc-dark, #707070);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.24px;
    margin-top: 10px;
  }
}
.person {
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .person_img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 15px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, #0055a5, #47adff);

    .imgperson {
      width: 100%;
      height: 100%;
    }
  }
  .person_information {
    margin-left: 70px;
    width: 754px;
    height: auto;
    padding-top: 11px;
    color: var(--mcc-dark, #707070);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.21px;
    // text-indent: 2em;
    line-height: 30px;
    .note {
      color: var(--mcc-primary-01, #0055a5);
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.75px;
      position: relative;
      line-height: 21px;
      top: 10px;
    }
  }
}
.viewAllbtn {
  width: 180px;
  height: 60px;
  border-radius: 5px;
  background: var(--mcc-primary-01, #0055a5);
  border: none;
  position: absolute;
  right: 180px;
  top: 70px;
  color: var(--sky-white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.viewAllbtn:hover {
  opacity: 0.8;
}
</style>