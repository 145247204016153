<template>
  <div class="header_body">
    <div class="header_logo">
      <img src="@/assets/imgs/mcc_logo.svg" />
      <span class="logo_text">MCC Singapore Enterprise</span>
    </div>
    <div class="header_data">
      <div class="header_data_left">
        <div class="left_title">
          Embark on an exciting journey into a world of possibilities
        </div>
        <div class="left_body" >
          Discover opportunities for growth and development, making a positive
          and meaningful impact
        </div>
        <div class="left_menu">
          <button class="JoinBtn" @click="openNew('http://165.21.98.153:18001/')">Join Us Now</button>
        </div>
      </div>
      <div class="header_data_right">
        <img src="@/assets/imgs/hr/graphic.svg" />
      </div>
    </div>
    <div class="header_bottom">
      <div class="bottom_item">
        <img src="@/assets/imgs/hr/logo-mcc-masked.svg" />
        <div class="bottom_data">
          <div class="bottom_data_title">Our Purpose</div>
          <div class="bottom_data_text">
            Our Purpose MCC Singapore strives to become a global, influential,
            and innovative developer and contractor, creating sustainable spaces
            for people to live, play, and travel while transforming the lives of
            millions in the region.
          </div>
        </div>
      </div>
      <div class="bottom_item">
        <img src="@/assets/imgs/hr/logo-mcc-masked.svg" />
        <div class="bottom_data">
          <div class="bottom_data_title">Our Vision</div>
          <div class="bottom_data_text">
            To be Global Leading 
            <br />
            Urbanisation Enterprise
          </div>
        </div>
      </div>
      <div class="bottom_item">
        <img src="@/assets/imgs/hr/logo-mcc-masked.svg" />
        <div class="bottom_data">
          <div class="bottom_data_title">Our Mission</div>
          <div class="bottom_data_text">
            Transforming Lives,
            <br />
            Inspiring Society
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    openNew(url){
      window.open(url)
    }
  }
};
</script>

<style lang="scss" scoped>
.header_body {
  width: 1440px;
  margin: auto;
  height: 800px;
  padding: 0px 170px 0px 170px;
  // background-color: aliceblue;
  padding-top: 4.6rem;
  box-sizing: border-box;
  font-family: Arial;
  .header_logo {
    // margin-top:146px ;
    width: 100%;
    height: auto;
    text-align: left;
    .logo_text {
      margin-left: 24px;
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.27px;
    }
  }
  .header_data {
    width: 100%;
    height: 436px;
    // background-color: bisque;
    display: flex;
    flex-direction: row;
    align-items: center;
    .header_data_left {
      width: 534px;
      height: auto;
      margin-right: 47px;
      .left_title {
        font-size: 36px;
        font-style: normal;
        line-height: 43.2px;
        font-weight: 700;
        letter-spacing: -0.54px;
        background: var(
          --primary-linear,
          linear-gradient(90deg, #3574af 0%, #47adff 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .left_body {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.24px;
        margin-top: 30px;
        line-height: 19.2px;
        color: var(--mcc-dark, #707070);
      }
      .left_menu {
        margin-top: 71px;
        .JoinBtn {
          width: 180px;
          height: 60px;
          padding: 21px 42px 23px 42px;
          text-align: center;
          border-radius: 5px;
          background: var(--mcc-primary-01, #0055a5);
          border: none;
          color: #ffffff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .JoinBtn:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .header_bottom {
    width: 100%;
    height: 304px;
    // background-color: cornsilk;
    position: relative;
    top: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .bottom_item {
      width: 356px;
      height: 304px;
      background-color: #ffffff;
      filter: drop-shadow(6px 9px 20px rgba(0, 0, 0, 0.1));
      border-radius: 18px;
      background-image: url("../../../assets/imgs/hr/bg.svg");
      background-repeat: no-repeat;
      background-position: 100% 0px;
      text-align: center;
    }

    .bottom_data {
      width: 100%;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0px 13px;
      box-sizing: border-box;
      transform: translate(-50%, -50%);
    }
    .bottom_data_title {
      color: var(--mcc-primary-01, #0055a5);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.3px;
    }
    .bottom_data_text {
      color: var(--mcc-dark, #707070);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      line-height: 16.8px;
      font-weight: 700;
      letter-spacing: -0.21px;
      margin-top: 20px;
      
    }
  }
}
</style>