<template>
  <div class="exploring">
    <div class="fillBg">
      <div class="fille_title">Exploring Growth and Success with us</div>
      <div class="fille_small_title">
        Employee Value Proposition Your Career Journey
      </div>
      <button class="openBtn" @click="openNew('/hr/evp')">
        Read more on EVP
      </button>
      <div class="fill_content">
        <template v-for="(item, i) in list">
          <div class="item_card_big" :key="i" v-if="i + 1 <= 3">
            <div class="card_img">
              <img style="width: 175px;;height: 150px;object-fit: contain" v-if="item.iconList.length>0" :src="item.iconList[0].link" alt="" />
            </div>
            <div class="card_title">{{ item.evp }}</div>
            <div class="card_content">
              <p v-html="item.description.replace(/\n/g,'<br>')"></p>
            </div>
          </div>
        </template>
        <template v-for="(item, i) in list">
          <div class="item_card_small" :key="i" v-if="i+1>3&&i+1<=7">
            <div class="card_img">
              <img style="width: 175px;;height: 150px;object-fit: contain" v-if="item.iconList.length>0" :src="item.iconList[0].link" alt="" />
            </div>
            <div class="card_title">{{ item.evp }}</div>
            <div class="card_content">
              <p v-html="item.description.replace(/\n/g,'<br>')"></p>
            </div>
          </div>
        </template>

 
      </div>
    </div>
  </div>
</template>

<script>
import { minxin } from "@/mixin.js";
export default {
  mixins: [minxin],
  mounted() {
    this.getList();
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async getList() {
      let response = await this.$http.get(
        `${this.$url.hr.evpList}?current=1&&size=7`
      );
      if (response.code == 200) {
        this.list = response.data.records;
      } else {
        this.list = [];
      }
      console.log(response);
    },
  },
};
</script>

<style lang="scss" scoped>
.exploring {
  font-family: Arial;
  width: 100%;
  margin: auto;
  height: 1300px;
  background-image: linear-gradient(to right, #0055a5, #47adff);
  .fillBg {
    width: 100%;
    height: 100%;
    // background-color: #47adff;
    background-image: url("../../../assets/imgs/hr/SubtractX2.svg");
    background-repeat: no-repeat;
    background-position: 50% 216px;
    text-align: center;
    position: relative;
    .fille_title {
      padding-top: 100px;
      font-size: 36px;
      font-weight: 700;
      color: #ffffff;
      line-height: 120%; /* 43.2px */
      letter-spacing: -0.54px;
    }
    .fille_small_title {
      padding-top: 10px;
      font-size: 20px;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
      letter-spacing: -0.3px;
      color: #ffffff;
    }
    .fill_content {
      width: 1100px;
      height: 904px;
      // padding-top: 100px;
      padding-bottom: 110px;
      box-sizing: border-box;
      //   border: 1px solid black;
      margin: auto;
      // margin-top: 20px;
      position: absolute;
      left: 0;
      right: 0;
      top: 240px;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      .card_img {
        width: 100%;
        height: 189px;
        padding-top: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // background-color:#47adff
      }
      .card_title {
        color: var(--mcc-primary-01, #0055a5);
        font-family: Arial;
        font-size: 20px;
        letter-spacing: -0.3px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        padding-left: 40px;
        width: 65%;
        // height: 39.8px;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; 
        
      }
      .card_content {
        margin-top: 18px;
        // margin-top: 5.5px;
        color: var(--mcc-dark, #707070);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        // padding-left: 40px;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 22px 0px 40px;
        text-align: left;
        line-height: 120%;
        letter-spacing: -0.21px;
        letter-spacing: -0.21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical; 
      }
      .item_card_big {
        width: 356px;
        height: 380px;
        background-color: #ffffff;
        border-radius: 10px;
        background-image: url("../../../assets/imgs/hr/bg_380.svg");
        background-repeat: no-repeat;
        background-position: 100% 0px;
      }
      .item_card_small {
        width: 263px;
        height: 380px;
        background-color: #ffffff;
        border-radius: 10px;
        background-image: url("../../../assets/imgs/hr/bg_380.svg");
        background-repeat: no-repeat;
        background-position: 100% 0px;
      }
    }
  }
}
.openBtn {
  width: 180px;
  height: 60px;
  border-radius: 5px;
  background: var(--mcc-primary-01, #0055a5);
  border: none;
  margin-top: 45px;
  color: var(--sky-white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}
.openBtn:hover {
  opacity: 0.8;
  // color: red;
}
</style>