<template>
  <div class="containter">
    <div class="flxed_menu" v-if="scrollHeight >= 500">
      <button class="applyBtn" :class="{ animationShowBtn: scrollHeight >= 500 }"
        @click="openNew('http://165.21.98.153:18001/')">
        Apply Now
      </button>
    </div>
    <div class="qrcodeImg" v-if="showQrcode">
      <div class="qrcode_header">
        <img src="@/assets/imgs/hr/icon-close.svg" @click="() => (showQrcode = !showQrcode)" />
      </div>
      <div class="qrcode_content">
        <img src="@/assets/imgs/hr/wxqrcode.svg" alt="" />
        <div class="qrcode_text">
          Scan QR code with WeChat
          <br />

          App to connect
        </div>
      </div>
    </div>

    <!-- <Header></Header> -->
    <headers></headers>
    <div class="block"></div>
    <div class="block_img">
      <div class="block_img_text">
        <div class="block_img_text_title">Your Dream Job Awaits</div>
        <div class="block_img_text_text">
          Experience Excellence in MCC Singapore
        </div>
      </div>
    </div>
    <avards></avards>
    <div class="blueLiner"></div>
    <talentAb></talentAb>
    <exploring> </exploring>
    <!-- <div class="block" style="height: 400px;"></div> -->
    <div class="fixed_bottom">
      <!-- <div class="footer">
        <div class="footer_content">
          <div class="footer_title">Connect with us</div>
          <div class="footer_text">
            Follow us on Social Media and Unlock Monthly Voucher Rewards!
          </div>
          <div class="footer_icon">
            <img src="@/assets/imgs/hr/footer_facebook.svg"
              @click="openNew('https://www.facebook.com/mccsglife/')" alt="" />
            <img src="@/assets/imgs/hr/footer_wx.svg" @click="() => (showQrcode = !showQrcode)"
              alt="" />
            <img src="@/assets/imgs/hr/footer_ins.svg"
              @click="openNew('https://sg.linkedin.com/company/mccsingapore')" alt="" />
            <img src="@/assets/imgs/hr/footer-youtobe.svg"
              @click="openNew('https://www.youtube.com/@mcclandsingapore4107')" alt="" />
            <img src="@/assets/imgs/hr/footer_cam.svg"
              @click="openNew('https://www.instagram.com/mccsglife/')" alt="" />
          </div>
        </div>
      </div> -->
      <Footer></Footer>
      <!-- <div class="footer_bottom">
        Copyright © 2023 MCC Singapore. All Rights Reserved
      </div> -->
    </div>
  </div>
</template>

<script>
import headers from './components/header.vue';
import avards from './components/avards.vue';
import talentAb from './components/talentAb.vue';
import Exploring from './components/exploring.vue';
import Footer from './components/footer.vue';
export default {
  components: {
    headers,
    avards,
    talentAb,
    Exploring,
    Footer,
  },
  data() {
    return {
      scrollHeight: 0,
      showQrcode: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollHeight = document.documentElement.scrollTop;
    });
  },
  methods: {
    openNew(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.containter {
  width: 100%;
  font-family: Arial;
  // height: 400px;
  // background-color: antiquewhite;
  margin: auto;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
}
.qrcodeImg {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.9);
  .qrcode_header {
    width: 100%;
    text-align: right;
  }
  .qrcode_content {
    border-radius: 8px;
    margin: auto;
    width: 326px;
    text-align: center;
    height: auto;
    background-color: #ffffff;
    margin-top: calc(50vh - 300px);
    padding: 24px;
    box-sizing: border-box;

    .qrcode_text {
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.8px; /* 16.8px */
      letter-spacing: -0.21px;
      margin-top: 16px;
    }
  }
}
.flxed_menu {
  margin: auto;
  overflow: hidden;
  text-align: right;
  width: 300px;
  text-align: right;
  position: fixed;
  // left: 0;
  right: 0;
  top: 400px;
  z-index: 900;
  .animationShowBtn {
    animation: fadenum 1s;
    animation-fill-mode: forwards;
  }
  @keyframes fadenum {
    100% {
      // opacity: 1;
      left: 0px;
    }
  }

  .applyBtn {
    position: relative;
    left: 1000px;
    width: 278px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--mcc-primary-01, #0055a5);
    border: none;
    color: var(--sky-white, #fff);
    text-align: center;
    // font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .applyBtn:hover {
    opacity: 0.8;
  }
}

.block {
  width: 100%;
  background-color: #ffffff;
  height: 250px;
}
.block_img {
  width: 100%;
  height: 300px;
  text-align: center;
  background-image: linear-gradient(to right, #0055a5, #47adff);
  // line-height: 300px;
  .block_img_text {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/imgs/hr/logo-mcc-masked-blue.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .block_img_text_title {
      color: #fff;
      text-align: center;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      /* 43.2px */
      letter-spacing: -0.54px;
    }
    .block_img_text_text {
      color: #fff;
      text-align: center;
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.3px;
      margin-top: 10px;
    }
  }
}
.blueLiner {
  width: 100%;
  height: 24px;
  background: var(
    --primary-linear,
    linear-gradient(90deg, #0055a5 0%, #47adff 100%)
  );
}
.fixed_bottom {
  // position: fixed;
  // z-index: 9999;
  // // border-top:1px solid red ;
  // bottom: 0px;
  // left: 0;
  // right: 0;
  // background-color: #ffffff;
  // box-shadow:6px 9px 20px rgba(0, 0, 0, 0.40) ;
}
.footer {
  width: 1440px;
  margin: auto;
  height: auto;
  padding: 0px 170px 0px 170px;
  box-sizing: border-box;
  .footer_content {
    width: 100%;
    height: 300px;
    background-image: url('../../assets/imgs/hr/animation-global-connect.svg');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    padding-top: 83px;
    box-sizing: border-box;
    .footer_title {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.54px;
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .footer_text {
      margin-top: 16px;
      color: var(--mcc-dark, #707070);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.24px;
    }
    .footer_icon {
      margin-top: 32px;
      img {
        margin-right: 60px;
      }
      img:hover {
        opacity: 0.81;
      }
    }
  }
}
.footer_bottom {
  // margin-top: 65px;
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: var(--mcc-dark, #707070);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.18px;
  background: #f6f6f8;
}
</style>