<template>
  <div class="awards">
    <div class="awards_body">
      <div class="recognition">
        <p class="recognition_title">Awards and Recognition</p>
        <p class="recognition_text">
          These awards and recognition serve as a testament to the exceptional
          talent, expertise, and dedication of MCC Singapore. By joining us,
          you'll have the opportunity to work alongside a highly esteemed team
          that consistently strives for excellence in our works. We believe in
          providing our professionals with the resources and support they need
          to drive meaningful impact, shape our organizational culture, and
          create a workplace where every individual can thrive.
        </p>
        <p class="recognition_text_black">
          We invite you to explore the exciting career opportunities within our
          company, and be part of an award-winning team that is committed to
          unlocking the full potential of our employees and re-define success
          with us.
        </p>
        <button class="viewAllbtn" @click="openNew('/hr/awards')">
          View all awards
        </button>
        <img
          class="awards_logo"
          src="@/assets/imgs/hr/medal_light.svg"
          alt=""
        />
      </div>
      <div class="awards_menu">
        <div
          v-for="(item, i) in list"
          :key="i"
          class="awards_card"
          @click="openNew(item.linkPC)"
        >
          <div class="awards_card_img">
            <img
              style="object-fit: contain"
              v-if="item.iconList.length > 0"
              :src="item.iconList[0].link"
              alt=""
            />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">{{ item.title | filterStr }}</div>
            <p class="awards_card_note">{{ item.channel | filterStr }}</p>
            <p class="awards_card_time">{{ item.year  }}</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <!-- <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=402')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/logo_bestEmployer.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">Ranked 172 of Best Employer</div>
            <p class="awards_card_note">Straits Time</p>
            <p class="awards_card_time">2023</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div> -->
        <!-- <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=380')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/logo_singapor.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">Top Employer in Singapore 2023</div>
            <p class="awards_card_note">Top Employer Institution</p>
            <p class="awards_card_time">2023</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=365')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/Subtract.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Best Companies to work for in Asia
            </div>
            <p class="awards_card_note">HR Asia</p>
            <p class="awards_card_time">2022</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=435')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/EXA.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Employee Experience in Best Use of Recruitment Tools – Silver
              Award
            </div>
            <p class="awards_card_note">HR Asia</p>
            <p class="awards_card_time">2023</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=353')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/HRTEAM.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Excellence in Best HR Team (MNC) Silver Award
            </div>
            <p class="awards_card_note">HR Asia</p>
            <p class="awards_card_time">2023</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=460')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/EXA.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Employee Experience in Best in House Learning Academy - Bronze
              Award
            </div>
            <p class="awards_card_note">HR Asia</p>
            <p class="awards_card_time">2022</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=461')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/CARE.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Workplace of the Year Care Champion
            </div>
            <p class="awards_card_note">Engage rocket</p>
            <p class="awards_card_time">2022</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=461')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/beftCompany.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Workplace of the Year Best Company Culture Bronze Award
            </div>
            <p class="awards_card_note">Engage rocket</p>
            <p class="awards_card_time">2022</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=332')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/Excellence.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Excellence in Digital Transformation Bronze Award
            </div>
            <p class="awards_card_note">HR Online</p>
            <p class="awards_card_time">2021</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=332')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/HrAwards.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Excellence in Learning and Development Bronze Standard
            </div>
            <p class="awards_card_note">SHRI</p>
            <p class="awards_card_time">2021</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="awards_card"
          @click="openNew('https://www.mcc.sg/news/?id=332')"
        >
          <div class="awards_card_img">
            <img src="@/assets/imgs/hr/HrAwards.svg" alt="" />
          </div>
          <div style="margin-left: 32px">
            <div class="awards_card_title">
              Excellence in Digital Transformation Silver Standard
            </div>
            <p class="awards_card_note">SHRI</p>
            <p class="awards_card_time">2021</p>
          </div>
          <div class="right_logo">
            <img src="@/assets/imgs/hr/icon-arrow-right.svg" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { minxin } from "@/mixin.js";
export default {
  mixins: [minxin],
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    filterStr(e) {
      if (e.length <= 30) {
        return e;
      }
      return e.substr(0, 30)+'...';
    },
  },
  methods: {
    async getList() {
      let response = await this.$http.get(
        `${this.$url.hr.awartList}?current=1&&size=12`
      );
      if (response.code == 200) {
        this.list = response.data.records;
      } else {
        this.list = [];
      }
      console.log(response);
    },
  },
};
</script>

<style lang="scss" scoped>
.awards {
  background: var(--mcc-light-grey, #f6f6f8);
  width: 100%;
  margin: auto;
  font-family: Arial;
  height: auto;
  // padding: 86px 170px 64px 170px;
  box-sizing: border-box;
  // position: relative;
  .awards_body {
    position: relative;
    width: 1440px;
    margin: auto;
    padding: 86px 170px 64px 170px;
    box-sizing: border-box;
  }
  .recognition {
    width: 100%;
    height: auto;
    .recognition_title {
      height: 40px;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.54px;
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .recognition_text {
      width: 727px;
      margin-top: 40px;
      color: var(--mcc-dark, #707070);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.24px;
      line-height: 24px;
    }
    .recognition_text_black {
      width: 727px;
      margin-top: 40px;
      color: var(--mcc-dark, #707070);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.3px;
    }
    .viewAllbtn {
      width: 180px;
      height: 60px;
      border-radius: 5px;
      background: var(--mcc-primary-01, #0055a5);
      border: none;
      position: absolute;
      right: 170px;
      top: 355px;
      color: var(--sky-white, #fff);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .viewAllbtn:hover {
      opacity: 0.8;
    }
    .awards_logo {
      position: absolute;
      top: 86px;
      right: 170px;
      // top: -200px;
    }
  }
  .awards_menu {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    .awards_card {
      box-shadow: 6px 9px 20px rgba(0, 0, 0, 0.1);
      width: 542px;
      height: 180px;
      background-color: #ffffff;
      border-radius: 10px;
      background-image: url("../../../assets/imgs/hr/bg_small.svg");
      background-repeat: no-repeat;
      background-position: 100% 0px;
      padding: 16px 32px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .awards_card_img {
        width: 156px;
        text-align: center;
        img {
          width: 150px;
          height: 150px;
        }
      }
      .awards_card_title {
        color: var(--mcc-primary-01, #0055a5);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.3px;
        width: 220px;
        height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      .right_logo {
        position: absolute;
        right: 32px;
      }
      .awards_card_note {
        color: var(--mcc-dark, #707070);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding-top: 8px;
        letter-spacing: -0.24px;
        // margin-top: 8px;
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .awards_card_time {
        color: var(--mcc-dark, #707070);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.24px;
        // margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .awards_card:hover {
      box-shadow: 6px 9px 20px rgba(0, 0, 0, 0.4);
    }
  }
}
</style>